<!-- 便民食堂 -->
<template>
  <div class="pinglun">
    <div>
      <!-- <van-field
        v-model="datafrom.AIdName"
        label="中介机构"
        required
        placeholder="请选择中介机构"
        @click="typeshow = true"
        readonly="readonly"
      />
      <van-popup v-model="typeshow" position="bottom" get-container="body">
        <van-picker
          show-toolbar
          :columns="aidList"
          value-key="Name"
          @cancel="typeshow = false"
          @confirm="ontypeshow"
        >
        </van-picker>
      </van-popup> -->
      <div style="margin-top: 10px">
        <span class="inputLabel">满意度</span>
        <van-rate
          v-model="datafrom.Satisfaction"
          :size="20"
          :gutter="10"
          color="#FF9E14"
          style="margin-left: 30px"
        />
      </div>
      <van-field
        v-model="datafrom.EvalContent"
        rows="3"
        autosize
        label="评价内容"
        type="textarea"
        placeholder="请输入评价内容"
        style="margin-top: 15px"
      />
      <div class="submit" style="padding: 10px 7px">
        <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
        <span style="color: #646566; font-size: 14px">图片</span>
      </div>
      <van-uploader
        v-model="jkImageList"
        multiple
        class="imgs"
        :max-count="1"
        :after-read="afterRead"
        :before-delete="jkDelete"
        :deletable="true"
      />
      <van-cell-group>
        <van-cell required title="匿名评价">
          <van-radio-group v-model="datafrom.OMNick" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="fromButton">
      <van-button
        style="width: 80%; margin: 10px 10%; border-radius: 25px"
        type="info"
        @click="tijiao()"
        >提 交</van-button
      >
    </div>
  </div>
</template>
<script>
import config from "@/config";
import axios from "axios";
import Compressor from "compressorjs";
import { WeGetAgencyPage } from "@/api/Hyapi";
import { WxEvaluateOrder } from "@/api/RealInfo";
import Vue from "vue";
import { Rate } from "vant";
import { Dialog } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
Vue.use(Dialog);
Vue.use(Rate);
export default {
  data() {
    return {
      OrderId: "",
      jkImageList: [],
      active: 3,
      datafrom: {
        OpenID: getOpenId(),
        OrderId: this.$route.query.orderId,
        Satisfaction: 0, //星级（1-一星、2-二星、3-三星、4-四星、5-五星)
        EvalContent: "", //评价内容，
        OMNick: 1,
      },
      typeshow: false,
      aidList: [{ id: 1, Name: "公司" }],
    };
  },
  created() {
    // this.GetAgencyPage();
  },
  methods: {
    //上传商品图片
    afterRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.data);
              that.datafrom.EvalImgs = res.data.picurl[0];
              // that.datafrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除商品图片
    jkDelete(file) {
      this.jkImageList = [];
      this.datafrom.EvalImgs = "";
    },

    tijiao() {
      if (this.datafrom.Satisfaction == 0) {
        Dialog.alert({
          title: "提示",
          message: "请选择服务评分!",
        }).then(() => {});
        return false;
      }
      if (this.datafrom.EvalContent == 0) {
        Dialog.alert({
          title: "提示",
          message: "请输入服务内容!",
        }).then(() => {});
        return false;
      }
      console.log(this.datafrom);
      WxEvaluateOrder(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "评价成功!",
          }).then(() => {
            this.$router.go(-1);
            this.datafrom = {
              OpenID: getOpenId(),
              OrderId: "",
              Satisfaction: 0, //星级（1-一星、2-二星、3-三星、4-四星、5-五星)
              EvalContent: "", //评价内容，
              OMNick: 1,
            };
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {});
        }
      });
    },
    ontypeshow(e) {
      console.log(e);
      this.datafrom.AId = e.AId;
      this.datafrom.AIdName = e.Name;
      this.typeshow = false;
    },
    GetAgencyPage() {
      WeGetAgencyPage().then((res) => {
        this.aidList = res.data.data;
      });
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}

.pinglun .center {
  width: 85%;
  margin: 30px 7.5%;
  background: #e6e6e6;
  border-radius: 8px;
}
.pinglun inputFrom {
  margin: 15px 8%;
}
.pinglun .inputFrom input {
  width: 93%;
  margin: 8px 2%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.pinglun .margin {
  margin: 15px 30px;
}
.pinglun .title {
  padding: 8px 12px;
}
.pinglun .idst {
  padding: 8px 12px;
}
.inputLabel {
  color: #646566;
  font-size: 14px;
  padding: 15px 16px;
}
</style>